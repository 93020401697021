const oa_sow_list = "/oa_sow_list";
const single_sow = "/single_sow";
const training_chart = "/training_chart";
const sow_leads_chart = "/sow_leads_chart";
const sow_training_list = "/sow_training_list";
const sow_training = "/sow_training";
const close_training = "/close_training";
const fse_training_list = "/fse_training_list";
const fse_attendance = "/fse_attendance";
const payouts_list = "/payouts_list";
const sow_fse_list = "/sow_fse_list";
const project_links = "/project_links";
const projects_leads_api = "/projects_leads_api";
const projects_graph = "/projects_graph";
const project_checkin = "/project_checkin";
const project_leads = "/project_leads";
const hourly_leads = "/hourly_leads";
const category_chart = "/category_chart";

export {
  oa_sow_list,
  category_chart,
  single_sow,
  training_chart,
  sow_leads_chart,
  sow_training_list,
  sow_training,
  close_training,
  fse_training_list,
  fse_attendance,
  payouts_list,
  sow_fse_list,
  project_links,
  project_checkin,
  project_leads,
  hourly_leads,
  projects_leads_api,
  projects_graph,
};
