import axios from "axios";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import { projects_graph } from "../../assets/utils/sow";
import { DashedLine } from "../../common/chart/LineChart";
import { api } from "../../globalConfig";

function LineChart() {
  const [dataInsert, setDataInsert] = React.useState({});

  const { id } = useParams();

  let pathName = "";
  useEffect(() => {
    pathName = api.COMMON_URL + projects_graph + `?project_id=${id}&type=month`;

    axios
      .get(pathName)
      .then((res) => {
        setDataInsert({
          data: res.data?.overall,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }, [id]);

  const handleDropDown = (e) => {
    // const pathName = api.OA_URL + overall_oa_leads;
    pathName = api.COMMON_URL + projects_graph + `?project_id=${id}&type=${e}`;

    axios
      .get(pathName)
      .then((res) => {
        setDataInsert({
          title: e == "month" ? "Month Stats" : "Overall Stats",
          data: res.data?.overall,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <React.Fragment>
      <Card>
        <CardHeader className="d-flex justify-content-between">
          <h4 className="card-title mb-0">Overall Leads</h4>
          <ButtonGroup>
            <UncontrolledDropdown>
              <DropdownToggle tag="button" className="btn btn-light ">
                Filter <i className="mdi mdi-chevron-down"></i>
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={() => handleDropDown("month")}>
                  Monthly leads
                </DropdownItem>
                <DropdownItem onClick={() => handleDropDown("date")}>
                  Overall leads
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </ButtonGroup>
        </CardHeader>
        <CardBody>
          {Object.keys(dataInsert).length > 0 ? (
            <DashedLine data={dataInsert} />
          ) : (
            <></>
          )}
        </CardBody>
      </Card>
    </React.Fragment>
  );
}

export default LineChart;
