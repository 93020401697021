import React from "react";
import { Link } from "react-router-dom";
import { Col, Row } from "reactstrap";

const BreadCrumbsLogo = ({ title, pageTitle }) => {
  return (
    <React.Fragment>
      <Row>
        <Col xs={12}>
          <div className="page-title-box d-sm-flex align-items-center justify-content-between">
            <Row className="d-flex gap-2 w-25">
              <Col md={2}>
                <img src={title?.logo} alt="logo" width={50} height={50} />
              </Col>
              <Col md={8} className="d-flex align-items-center">
                <h4 className="m-0">{title?.project_name}</h4>
              </Col>
            </Row>

            <div className="page-title-right">
              <ol className="breadcrumb m-0">
                <li className="breadcrumb-item">
                  <Link to="#">{pageTitle}</Link>
                </li>
                <li className="breadcrumb-item active">
                  {title?.project_name}
                </li>
              </ol>
            </div>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default BreadCrumbsLogo;
