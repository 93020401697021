import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Card, CardBody, Col, Container, Row, Spinner } from "reactstrap";
import {
  category_chart,
  hourly_leads,
  project_checkin,
  project_leads,
} from "../../assets/utils/sow";
import { title } from "../../common/pathName";
import BreadCrumbsLogo from "../../components/common/BreadCrumbsLogo";
import BarChart from "../chart/BarChart";
import PieCharts from "../chart/Echart";
import DataTables from "../Tables/DataTables/DataTables";
import TileBoxs from "../widgets/TileBox";
import base64 from "base-64";
import { project_backend_graph } from "../../assets/utils/common";
import LineChart from "../chart/LineChart";
import { api } from "../../globalConfig";
import CountUp from "react-countup";
import { projStats } from "../../assets/utils/dashboard";

const DashboardMainPage = () => {
  const [customHoverTab, setcustomHoverTab] = React.useState("1");
  const [clickid, setClickId] = React.useState(undefined);
  const [clickid2, setClickId2] = React.useState(undefined);
  const [clickid3, setClickId3] = React.useState(undefined);

  const [titlename, setTitleName] = React.useState("");
  const [data1, setData1] = React.useState({});
  const [data2, setData2] = React.useState({});
  const [data3, setData3] = React.useState({});
  const [errData3, setErrorData3] = useState(false);
  // const [hourlyleads, setHourlyleads] = React.useState(undefined);
  const [colorCode, setColorCode] = React.useState("#FFF");
  const [leadsType, setLeadsType] = React.useState("");
  const [prjStats, setPrjStats] = useState({
    total: "---",
    approved: "---",
    pending: "---",
    Rejected: "---",
    today_approved: "-",
    today_Rejected: "-",
    today_total: "-",
    today_pending: "-",
  });
  const [loading, setLoading] = React.useState(false);
  const [chk, setChk] = useState(false);

  const path = useLocation();

  const prjId = path.pathname.split("/")[2];
  const prjIds = JSON.parse(sessionStorage.getItem("projectID"));
  const idnum = Number(prjId);

  useEffect(() => {
    if (prjIds.includes(idnum)) {
      const path = api.FARM_URL + projStats;
      setLoading(true);
      axios
        .get(path, { params: { project_id: prjId } })
        .then((res) => {
          setPrjStats(res.data);
          setLoading(false);
        })
        .catch((err) => console.log(err));
    }
  }, [prjId, chk]);

  const cardMap = [
    {
      label: "Total ",
      labelClass: "muted",
      counter: prjStats.total,
      decimals: 0,
      separator: ",",
      suffix: "",
      icon: "mdi mdi-briefcase-check-outline",
      iconClass: "primary",
      percentage: prjStats.today_total,
      percentageClass: "primary",
      prefix: "",
    },
    {
      label: "Approved ",
      labelClass: "muted",
      counter: prjStats?.approved,

      icon: "ri ri-thumb-up-line",
      iconClass: "success",
      percentage: prjStats.today_approved,
      percentageClass: "success",
      decimals: 0,
      separator: ",",
      suffix: "",
      prefix: "",
    },
    {
      icon: "mdi mdi-briefcase-clock-outline pending-icon",
      iconClass: "warning",
      label: "QC-Pending ",
      labelClass: "muted",
      counter: prjStats.pending,
      decimals: 0,
      percentage: prjStats.today_pending,
      percentageClass: "warning",
      separator: ",",
      suffix: "",
      prefix: "",
    },
    {
      icon: "mdi mdi-briefcase-remove-outline ",
      iconClass: "danger",
      label: "Rejected ",
      labelClass: "muted",
      counter: prjStats.Rejected,
      decimals: 0,
      percentage: prjStats.today_Rejected,
      percentageClass: "danger",
      separator: ",",
      suffix: "",
      prefix: "",
    },
    {
      icon: "mdi mdi-briefcase-edit-outline ",
      iconClass: "info",
      label: "Incomplete ",
      labelClass: "muted",
      counter: prjStats.incomplete,
      decimals: 0,
      percentage: prjStats.today_incomplete,
      percentageClass: "danger",
      separator: ",",
      suffix: "",
      prefix: "",
    },
  ];

  const customHovertoggle = (tab) => {
    if (customHoverTab !== tab) {
      setcustomHoverTab(tab);
    }
  };

  const final = path.pathname.slice(1);
  const split = final.split("/");

  const Cap = split[0][0].toUpperCase() + split[0].slice(1);

  document.title = `${Cap} | ${title}`;

  const dataProject = JSON.parse(sessionStorage.getItem("project"));

  const config = {
    headers: {
      "x-access-token": sessionStorage.getItem("token"),
    },
  };

  const code = path.search?.split(":");

  var bytes = base64.decode(code[1]);

  const projectId = path.pathname.split("/")[2];

  const dateStatus = ["date", "yearweek", "month", "year"];

  useEffect(() => {
    var postData = {
      date_status: dateStatus[Number(clickid)] || "month",
      project_name: bytes,
      approved_status: "",
    };

    const dataColor = JSON.parse(sessionStorage.getItem("project"));
    setColorCode(dataColor.color_code);

    const subapi = project_leads;
    const apiLink = api.COMMON_URL + subapi;

    axios
      .post(apiLink, postData, config)
      .then((res) => {
        const dataObj = {
          name: res.data.name,
          value: res.data.value,
        };
        setData1(dataObj);
      })
      .catch((err) => console.log(err));
  }, [path, clickid]);

  useEffect(() => {
    const postData = {
      date_status: dateStatus[Number(clickid2)] || "month",
      project_name: bytes,
      approved_status: "approved",
    };

    const subapi = project_leads;
    const api = project_backend_graph + subapi;

    axios

      .post(api, postData, config)
      .then((res) => {
        const dataObj = {
          name: res.data.name,
          value: res.data.value,
        };
        setData2(dataObj);
      })
      .catch((err) => console.log(err));
  }, [path, clickid2]);

  useEffect(() => {
    const api = project_backend_graph + category_chart;
    setErrorData3(false);
    axios
      .get(api, {
        params: {
          project_name: bytes,
          project_id: projectId,
          date_status: dateStatus[Number(clickid3)] || "month",
        },
      })
      .then((res) => {
        const dataObj = {
          name: res?.data?.category_list?.name,
          value: res?.data?.category_list?.value,
        };
        setData3(dataObj);
      })
      .catch((err) => setErrorData3(true));
  }, [path, projectId, clickid3]);

  // useEffect(() => {
  //   const postData = {
  //     date_status:
  //       (clickid3 &&
  //         titlename == "Total Checkin" &&
  //         dateStatus[Number(clickid3)]) ||
  //       "date",
  //     project_id: projectId,
  //   };

  //   const subapi = project_checkin;
  //   const api = project_backend_graph + subapi;

  //   axios
  //     .post(api, postData, config)
  //     .then((res) => {
  //       const dataObj = {
  //         name: res.data.name,
  //         value: res.data.value,
  //       };
  //       setData3(dataObj);
  //     })
  //     .catch((err) => console.log(err));
  // }, [path, clickid3]);

  // useEffect(() => {
  //   const subapi = hourly_leads;
  //   const api = project_backend_graph + subapi;
  //   const postData = {
  //     project_name: bytes,
  //     type: leadsType,
  //   };
  //   axios
  //     .post(api, postData, config)
  //     .then((res) => {
  //
  //       const hourData = {
  //         name: res.data.name,
  //         value: res.data.value,
  //       };
  //       setHourlyleads(hourData);
  //     })
  //     .catch((err) => console.log(err));
  // }, [path, leadsType]);

  return loading ? (
    <div
      className="page-content"
      style={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Spinner color="primary"> Loading... </Spinner>
    </div>
  ) : (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumbsLogo
            title={dataProject}
            pageTitle="Dashboard"
            setClickId={setClickId}
            setTitleName={setTitleName}
          />
          <TileBoxs />
          {/* <Row>
            <Col md={errData3 ? 6 : 4}>
              {
                <PieCharts
                  title={"Leads"}
                  color={colorCode}
                  setClickId={setClickId}
                  setTitleName={setTitleName}
                  data1={data1}
                />
              }
            </Col>
            <Col md={errData3 ? 6 : 4}>
              {
                <PieCharts
                  title={"Approved Leads"}
                  color={colorCode}
                  setClickId={setClickId2}
                  setTitleName={setTitleName}
                  data1={data2}
                />
              }
            </Col>

            {!errData3 ? (
              <Col md={4}>
                {
                  <PieCharts
                    title={"Category Leads"}
                    color={colorCode}
                    setClickId={setClickId3}
                    setTitleName={setTitleName}
                    data1={data3}
                    err={errData3}
                  />
                }
              </Col>
            ) : (
              ""
            )}
            <Col md={4}>
              {
                <PieCharts
                  title={"Total Checkin"}
                  color={colorCode}
                  setClickId={setClickId3}
                  setTitleName={setTitleName}
                  data1={data3}
                />
              }
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <BarChart
                // leads={hourlyleads}
                setLeadsType={setLeadsType}
                color={colorCode}
              />
            </Col>
            <Col md={6}>
              <LineChart />
            </Col>
          </Row> */}
          <Row>
            <Col
              sm={4}
              className="d-flex gap-3 "
              style={{ flexGrow: "0.64", flexShrink: "1" }}
            >
              {(cardMap || []).map((item, key) => (
                <Col key={key} md={3}>
                  <Card
                    className={"card-animate " + item.bgColor}
                    style={{ width: "100%", flexShrink: "1" }}
                  >
                    <CardBody>
                      <div className="d-flex align-items-center px-0">
                        <div className="avatar-sm flex-shrink-0">
                          <span
                            className={
                              "avatar-title rounded-circle fs-3 bg-soft-" +
                              item.iconClass +
                              " text-" +
                              item.iconClass
                            }
                          >
                            <i className={item.icon}></i>
                          </span>
                        </div>
                        <div className="flex-grow-1 ms-3">
                          <p
                            className={
                              "text-uppercase fw-medium mb-1 text-" +
                              item.labelClass
                            }
                          >
                            {item.label}
                          </p>
                          <h4 className={"fs-4 mb-1 " + item.counterClass}>
                            <span className="counter-value">
                              {/* {item.counter !== undefined && (
                                <CountUp
                                  start={0}
                                  prefix={item.prefix}
                                  suffix={item.suffix}
                                  separator={item.separator}
                                  end={item.counter && item.counter}
                                  decimals={item.decimals}
                                  duration={0}
                                />
                              )} */}

                              {item.counter}
                            </span>
                          </h4>
                          <p className={"mb-0 text-" + item.captionClass}>
                            {item.caption}
                          </p>
                        </div>
                        <div className="flex-shrink-0 align-self-center">
                          <span
                            className={
                              "fs-12 badge badge-soft-" + item.percentageClass
                            }
                          >
                            <i
                              className={
                                "fs-13 align-middle me-1 " + item.badge
                              }
                            ></i>
                            {item.percentage}
                            <span></span>
                          </span>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              ))}
            </Col>
          </Row>
          <DataTables setChk={setChk} chk={chk} />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default DashboardMainPage;
