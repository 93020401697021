import React from "react";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
// import BreadCrumb from '../../../Components/Common/BreadCrumb';

import {
  BasicTable,
  ScrollVertical,
  ScrollHorizontal,
  AlternativePagination,
  FixedHeaderDatatables,
  ModalDataDatatables,
  AjaxDatatables,
} from "./DatatableCom";

const DataTables = ({ setChk, chk }) => {
  //   document.title = "Datatables | Velzon - React Admin & Dashboard Template";
  return (
    <React.Fragment>
      <Container fluid>
        {/* <BreadCrumb title="Datatables" pageTitle="Tables" /> */}
        <Row>
          <Col lg={12}>
            <BasicTable setChk={setChk} chk={chk} />
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default DataTables;
