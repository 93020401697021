import React from "react";
import Route from "./routes";

import "./assets/scss/themes.scss";

import "./App.css";

function App() {
  return (
    <React.Fragment>
      <Route />
    </React.Fragment>
  );
}

export default App;
