import React, { useEffect } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import { Link, useLocation, useParams } from "react-router-dom";

import CountUp from "react-countup";

//Import Icons
import FeatherIcon from "feather-icons-react";
import axios from "axios";

import base64 from "base-64";
import { sessionToken } from "../../assets/config/sessionToken";
import { api } from "../../globalConfig";

// import { tileBoxs1, tileBoxs2, tileBoxs3, tileBoxes4 } from "../../common/data/index";

const TileBoxs = () => {
  const tileBoxs1 = [
    {
      id: 1,
      label: "Total Leads",
      labelClass: "muted",
      percentage: "+16.24 %",
      percentageClass: "success",
      percentageIcon: "ri-arrow-right-up-line",
      counter: "559.25",
      caption: "View net earnings",
      icon: "bx bx-dollar-circle",
      iconClass: "success",
      decimals: 2,
      prefix: "",
      suffix: "",
    },
    {
      id: 2,
      label: "Total Attempted",
      labelClass: "muted",
      percentage: "-3.57 %",
      percentageClass: "success",
      percentageIcon: "ri-arrow-right-up-line",
      counter: "36894",
      caption: "View all orders",
      icon: "bx bx-shopping-bag",
      iconClass: "success",
      // bgColor: "bg-info",
      // counterClass: "text-white",
      // captionClass: "text-white-50",
      decimals: 0,
      prefix: "",
      separator: ",",
      suffix: "",
    },
    {
      id: 3,
      label: "QC Passed",
      labelClass: "muted",
      percentage: "+29.08 %",
      percentageClass: "success",
      percentageIcon: "ri-arrow-right-up-line",
      counter: "183.35",
      caption: "See details",
      icon: "bx bx-user-circle",
      iconClass: "warning",
      decimals: 2,
      prefix: "",
      suffix: "",
    },
    {
      id: 4,
      label: "Total Checking",
      labelClass: "muted",
      percentage: "+0.00 %",
      percentageClass: "success",
      percentageIcon: "ri-arrow-right-up-line",
      counter: "165.89",
      caption: "Withdraw money",
      icon: "bx bx-wallet",
      iconClass: "primary",
      decimals: 2,
      prefix: "",
      separator: ",",
      suffix: "",
    },
  ];

  const path = useLocation();
  const id = useParams();

  const [statsData, setStatsData] = React.useState([]);
  const final = path.pathname.slice(1);
  const split = final.split("/");

  const Cap = split[0][0].toUpperCase() + split[0].slice(1);

  // const location = useLocation()
  const code = path.search?.split(":");

  var bytes = base64.decode(code[1]);
  // var bytesApi = base64.decode(code[3]);
  const apiLink = api.PRJ_URL + "/product_stats";
  const dataEnter = {
    project_name: bytes,
    project_id: id?.id,
  };
  const config = {
    headers: {
      "x-access-token": sessionToken,
    },
  };

  useEffect(() => {
    axios
      .post(apiLink, dataEnter)
      .then((res) => {
        const response = res?.data?.stats[0];

        const dataStats = [
          {
            id: 1,
            label: "Total Leads",
            labelClass: "muted",
            percentage: response?.today_total_leads,
            percentageClass:
              response?.today_total_leads == 0 ? "danger" : "success",
            percentageIcon:
              response?.today_total_leads != 0
                ? "ri-arrow-up-line"
                : "ri-arrow-down-line",
            counter: response?.total_leads,
            caption: "View net earnings",
            icon: "bx bx-shopping-bag",
            iconClass: "success",
            decimals: 0,
            prefix: "",
            suffix: "",
            // bgColor: "bg-success",
          },
          // {
          //   id: 2,
          //   label: "Total Earnings",
          //   labelClass: "muted",
          //   percentage: response?.today_earning,
          //   percentageClass:
          //     response?.today_earning == 0 ? "danger" : "success",
          //   percentageIcon:
          //     response?.today_earning != 0
          //       ? "ri-arrow-up-line"
          //       : "ri-arrow-down-line",
          //   counter: response?.total_earnings,
          //   caption: "View all orders",
          //   icon: "bx bx-dollar-circle",
          //   iconClass: "success",
          //   decimals: 0,
          //   prefix: "₹",
          //   separator: ",",
          //   suffix: "",
          // },
          {
            id: 3,
            label: "QC Passed",
            labelClass: "muted",
            percentage: response?.today_approved_leads,
            percentageClass:
              response?.today_approved_leads == 0 ? "danger" : "success",
            percentageIcon:
              response?.today_approved_leads != 0
                ? "ri-arrow-up-line"
                : "ri-arrow-down-line",
            counter: response?.approved_leads,
            caption: "See details",
            icon: "bx bx-wallet",
            iconClass: "success",
            decimals: 0,
            prefix: "",
            suffix: "",
          },
          {
            id: 4,
            label: "Pending Leads",
            labelClass: "muted",
            percentage: response?.today_pending_leads,
            percentageClass:
              response?.today_pending_leads == 0 ? "danger" : "success",
            percentageIcon:
              response?.today_pending_leads != 0
                ? "ri-arrow-up-line"
                : "ri-arrow-down-line",
            counter: response?.pending_leads,
            caption: "Withdraw money",
            icon: "bx bx-dollar-circle",
            iconClass: "warning",
            decimals: 0,
            prefix: "",
            separator: ",",
            suffix: "",
          },
        ];
        setStatsData([...dataStats]);
      })
      .catch((err) => console.log(err.response, "err"));
  }, [path]);

  return (
    <React.Fragment>
      {/* <Row>
                <Col className="col-12">
                    <h5 className="text-decoration-underline mb-3 pb-1">Tile Boxs</h5>
                </Col>
            </Row> */}
      {statsData && (
        <Row className="d-flex justify-content-center gap-5">
          {(statsData || []).map((item, key) => (
            <Col xl={3} md={3} key={key}>
              <Card className={"card-animate " + item.bgColor}>
                <CardBody>
                  <div className="d-flex align-items-center">
                    <div className="avatar-sm flex-shrink-0">
                      <span
                        className={
                          "avatar-title rounded-2 fs-2 bg-soft-" +
                          item.iconClass +
                          " text-" +
                          item.iconClass
                        }
                      >
                        <i className={item.icon}></i>
                      </span>
                    </div>
                    <div className="flex-grow-1 ms-3">
                      <p
                        className={
                          "text-uppercase fw-medium mb-3 text-" +
                          item.labelClass
                        }
                      >
                        {item.label}
                      </p>
                      <h4 className={"mb-3 " + item.counterClass}>
                        <span
                          className="counter-value"
                          style={{ fontSize: "2.21875rem" }}
                        >
                          <CountUp
                            start={0}
                            prefix={item.prefix}
                            suffix={item.suffix}
                            separator={item.separator}
                            end={item.counter}
                            decimals={item.decimals}
                            duration={1}
                          />
                        </span>
                      </h4>
                      {/* <p className={"mb-0 text-" + item.captionClass}>
                        {item.caption}
                      </p> */}
                    </div>
                    <div className="flex-shrink-0 align-self-center">
                      <span
                        className={
                          "fs-18 badge badge-soft-" + item.percentageClass
                        }
                      >
                        <i className={item.percentageIcon}></i>
                        {item.percentage}
                        <span></span>
                      </span>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          ))}
        </Row>
      )}
    </React.Fragment>
  );
};

export default TileBoxs;
