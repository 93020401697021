import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

//import Components
import Header from "./Header";
import Sidebar from "./Sidebar";
// import Footer from "./Footer";
// import RightSidebar from "../components/common/RightSidebar";

//import actions
import { changeLayoutMode } from "../store/actions";

//redux
import { useSelector, useDispatch } from "react-redux";
import { topbarThemeTypes } from "../components/constants/layout";

const Layout = (props) => {
  const [headerClass, setHeaderClass] = useState("");
  const [sideBar, setSideBar] = useState(false);
  const dispatch = useDispatch();
  const { layoutType, layoutModeType } = useSelector((state) => ({
    layoutType: state.Layout.layoutType,
    layoutModeType: state.Layout.layoutModeType,
  }));

  const clientWidth = document.documentElement.clientWidth;

  /*
    layout settings
    */
  useEffect(() => {
    if (layoutModeType) {
      dispatch(changeLayoutMode(layoutModeType));
    }
  }, [layoutModeType, dispatch]);
  /*
    call dark/light mode
    */
  const onChangeLayoutMode = (value) => {
    // console.log(value);

    if (changeLayoutMode) {
      // if
      dispatch(changeLayoutMode(value));
    }
  };

  // class add remove in header
  useEffect(() => {
    window.addEventListener("scroll", scrollNavigation, true);
  }, []);

  function scrollNavigation() {
    var scrollup = document.documentElement.scrollTop;
    if (scrollup > 50) {
      setHeaderClass("topbar-shadow");
    } else {
      setHeaderClass("");
    }
  }

  return (
    <React.Fragment>
      <div id="layout-wrapper">
        <Header
          headerClass={headerClass}
          layoutModeType={layoutModeType}
          onChangeLayoutMode={onChangeLayoutMode}
          setSideBar={setSideBar}
          sideBar={sideBar}
        />
        <Sidebar
          layoutType={layoutType}
          sideBar={sideBar}
          setSideBar={setSideBar}
        />
        <div
          className="main-content"
          style={sideBar ? { marginLeft: "0" } : { outline: "none" }}
        >
          {props.children}
          {/* <Footer /> */}
        </div>
      </div>
      {/* <RightSidebar /> */}
    </React.Fragment>
  );
};

Layout.propTypes = {
  children: PropTypes.object,
};

export default withRouter(Layout);
