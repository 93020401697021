import axios from "axios";
import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Toast,
  ToastBody,
  UncontrolledDropdown,
} from "reactstrap";
import classStyle from "../payout.module.css";
import { APIClient, sessionToken } from "../../../assets/config/sessionToken";
import base64 from "base-64";
import { useLocation, useParams } from "react-router-dom";
import DataTableExtensions from "react-data-table-component-extensions";
import { api } from "../../../globalConfig";
import { extract_token } from "../../../assets/utils/common";
import ImageModel from "../../imageModel/ImageModel";
import { projects_leads_api, project_links } from "../../../assets/utils/sow";
import { CSVLink, CSVDownload } from "react-csv";
import {
  projStats,
  qcSubmitclient,
  tabledata,
} from "../../../assets/utils/dashboard";
import { ToastContainer, toast } from "react-toastify";
import { successnotify } from "../../toasts/Toasts";

// import { successnotify } from "../../toasts/Toasts";

export const BasicTable = ({ setChk, chk }) => {
  const path = useLocation();
  const [urlTable, setUrlTable] = useState("");
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [colm, setColm] = useState([]);
  const [view, setView] = useState([]);
  const [image, setImage] = useState("");
  const [admin, setAdmin] = useState(false);
  const [modal_center, setmodal_center] = useState(false);
  const [open, setOpen] = useState(false);
  const [openNcml, setOpenNcml] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);
  const [dataArray, setDataArray] = useState({});
  const [viewArray, setViewArray] = useState([]);
  const [colArray, setColArray] = useState([]);
  const [rowData, setRowData] = useState({});
  const [imageData, setImageData] = useState("");
  const [leadData, setLeadData] = useState([]);
  const [appRemark, setAppRemark] = useState("");
  const [rejRemark, setRejRemark] = useState("");
  const [check, setCheck] = useState(false);
  const [user_type, set_user_type] = useState("");

  // const [toast1, setToast1] = useState(false);

  const token = sessionStorage.getItem("token");

  const final = path.pathname.slice(1);
  const split = final.split("/");

  const Cap = split[0][0].toUpperCase() + split[0].slice(1);

  const code = path.search?.split(":");

  var bytes = base64.decode(code[1]);
  const pathName = api.COMMON_URL + extract_token;

  const location = useLocation();
  const prjId = location.pathname?.split("/")[2];

  // const bytesApi =
  const dataEnter = {
    project_name: bytes,
    project_id: path.pathname,
  };
  const config = {
    headers: {
      "x-access-token": sessionToken,
    },
  };
  const id = useParams();
  const prjIds = JSON.parse(sessionStorage.getItem("projectID"));
  const idnum = Number(id.id);

  function tog_center() {
    setmodal_center(!modal_center);
  }
  const handleLeads = (row) => {
    setRowData(row);

    const urlData = row?.table_data + `&lead_id=${row?.main_lead}`;

    axios
      .get(urlData)
      .then((res) => {
        setOpen(true);
        const colString = res.data.col;
        const colData = colString.slice(0, -1).split(",");
        const string = res.data.view;
        const data = string.slice(0, -1).split(",");
        setViewArray(data);

        setColArray(colData);
        setDataArray(res.data.leads[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    axios
      .get(pathName)
      .then((res) => {
        if (res.data?.admin == "admin") {
          setAdmin(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });

    if (prjIds.includes(idnum)) {
      const urlApi = api.COMMON_URL + projects_leads_api;
      const urlData = new APIClient();
      setLoading(true);
      urlData
        .get(urlApi, { project_id: id.id })
        .then((res) => {
          setUrlTable(res.data.leads);
          setLoading(false);
          // axios
          //   .get(res.data.data?.table_data)
          //   .then((res) => {
          //     setTableData(res.data.leads);
          //     const string = res.data.view;
          //     const data = string.slice(0, -1).split(",");
          //     setView(data);
          //     const colString = res.data.col;
          //     const colData = colString.slice(0, -1).split(",");
          //     setColm(colData);
          //   })
          //   .catch((err) => console.log(err.response, "err"));
        })
        .catch((err) => console.log(err.response, "err"));
    }
  }, [path, admin, check]);

  useEffect(() => {
    const pathName = api.COMMON_URL + extract_token;

    axios
      .get(pathName)
      .then((res) => {
        set_user_type(res.data?.admin);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleLeadDet = (row) => {
    const path = api.FARM_URL + tabledata;
    setRowData(row);
    axios
      .get(path, { params: { project_id: prjId, lead_id: row.main_lead } })
      .then((res) => {
        if (prjId == 337) {
          setOpen(true);
        } else {
          setOpenNcml(true);
        }

        setLeadData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // const successnotify = () =>
  //   toast("Your application was successfully sent", {
  //     position: "top-center",
  //     hideProgressBar: true,
  //     closeOnClick: false,
  //     className: "bg-success text-white",
  //   });

  // <div style={{ zIndex: "11" }}>
  //   <Toast
  //     isOpen={toast1}
  //     id="borderedToast1"
  //     className="toast-border-primary overflow-hidden mt-3"
  //   >
  //     <ToastBody>
  //       <div className="d-flex align-items-center">
  //         <div className="flex-shrink-0 me-2">
  //           <i className="ri-user-smile-line align-middle"></i>
  //         </div>
  //         <div className="flex-grow-1">
  //           <h6 className="mb-0">Your application was successfully sent.</h6>
  //         </div>
  //       </div>
  //     </ToastBody>
  //   </Toast>
  // </div>;

  const handleApprove = () => {
    const path = api.FARM_URL_QC + "/" + qcSubmitclient;
    const postData = {
      lead_id: rowData.main_lead,
      qc_status: "1",
      qc_remark: appRemark,
    };

    axios
      .put(path, postData)
      .then((res) => {
        // successnotify("success");
        setOpen3(!open3);
        setTimeout(() => {
          setOpen(false);
          setOpenNcml(false);
        }, 300);
        setCheck(!check);
        setChk(!chk);
      })
      .catch(() => alert("error"));
  };
  const handleReject = () => {
    const path = api.FARM_URL_QC + "/" + qcSubmitclient;
    const postData = {
      lead_id: rowData.main_lead,
      qc_status: "3",
      qc_remark: rejRemark,
    };

    axios
      .put(path, postData)
      .then((res) => {
        setOpen4(!open4);
        setTimeout(() => {
          setOpen(false);
          setOpenNcml(false);
        }, 300);
        setCheck(!check);
        setChk(!chk);
      })
      .catch(() => alert("error"));
  };

  const columns = [
    {
      name: <span className="font-weight-bold fs-13">Lead ID.</span>,
      selector: (row) => row.main_lead,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Date</span>,
      selector: (row) => row.date,
      sortable: true,
    },

    {
      name: <span className="font-weight-bold fs-13">Merchant Name</span>,
      selector: (row) => row.merchant_name,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Merchant Number</span>,
      selector: (row) => row.merchant_number,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">City</span>,
      selector: (row) => row.city,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">State</span>,
      selector: (row) => row.state,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">QC Status</span>,
      sortable: true,
      selector: (cell) => {
        switch (cell.final_qc) {
          case "rejected":
            return (
              <span className="badge badge-soft-danger"> {cell.final_qc} </span>
            );
          case "pending":
            return (
              <span className="badge badge-soft-warning">
                {" "}
                {cell.final_qc}{" "}
              </span>
            );

          default:
            return (
              <span className="badge badge-soft-success">
                {" "}
                {cell.final_qc}{" "}
              </span>
            );
        }
      },
    },
    {
      name: <span className="font-weight-bold fs-13 ">View</span>,
      sortable: true,
      center: true,
      width: "100px",
      cell: (row) => (
        <i
          className="ri-eye-fill align-bottom me-2 text-muted cursor-pointer"
          onClick={() => {
            // handleLeads(row);
            handleLeadDet(row);
          }}
        ></i>
      ),
    },
  ];

  // if (admin) {
  //   columns.splice(2, 0, {
  //     name: <span className="font-weight-bold fs-13">FSE Name</span>,
  //     selector: (row) => row.fse_name,
  //     sortable: true,
  //   });
  // }
  // if (colm.length > 0) {
  //   colm.map((v, i) => {
  //     columns.splice(i + 5, 0, {
  //       name: <span className="font-weight-bold fs-13">{v}</span>,
  //       selector: (row) => row[`${v}`],
  //       sortable: true,
  //     });
  //   });
  // }
  // if (view.length > 0) {
  //   view.map((v, i) => {
  //     columns.splice(i + 5, 0, {
  //       name: <span className="font-weight-bold fs-13">{v}</span>,
  //       selector: (row) => (
  //         <Button
  //           size="sm"
  //           color="info"
  //           outline
  //           onClick={() => {
  //             tog_center();
  //             setImage(row[`${v}`]);
  //           }}
  //         >
  //           view
  //         </Button>
  //       ),
  //       sortable: true,
  //       center: true,
  //     });
  //   });
  // }

  const tableEnter = {
    columns,
    data: urlTable,
  };

  return loading ? (
    <h4 className="text-center">loading table content.... </h4>
  ) : (
    <>
      {tableEnter && (
        <Card>
          <CardHeader style={{ marginBottom: "-5px", background: "#F3F3F8" }}>
            <CSVLink
              style={{
                float: "right",
                marginTop: "-5px",
                marginRight: "220px",
              }}
              className="btn btn-primary "
              data={urlTable}
            >
              <i className=" ri-download-2-line align-middle me-1"></i>
              Download
            </CSVLink>
          </CardHeader>
          <CardBody>
            <DataTableExtensions
              {...tableEnter}
              export={false}
              print={true}
              filterHidden={true}
              filterPlaceholder={`Search`}
            >
              <DataTable columns={columns} data={tableEnter.data} pagination />
            </DataTableExtensions>
          </CardBody>
        </Card>
      )}

      <Modal
        isOpen={modal_center}
        toggle={() => {
          tog_center();
        }}
        centered={true}
      >
        <ImageModel img2={image} />
      </Modal>
      {openNcml ? (
        <>
          <Modal
            // className="modal-fullscreen"
            id="exampleModalFullscreen"
            tabIndex="-1"
            isOpen={openNcml}
            toggle={() => {
              setOpenNcml(false);
              setRowData({});
            }}
            centered={true}
            size={"xl"}
          >
            <div className="d-flex justify-content-end align-items-center">
              <a
                href={`https://farming-backend.taskmo.in/common/downloadLead?lead_id=${rowData.main_lead}&token=${token}&project_id=${prjId}`}
                target="_blank"
                rel="noreferrer"
                download
                className="cursor-pointer "
              >
                <i
                  className=" ri-download-2-line text-primary "
                  style={{ fontSize: "19px" }}
                ></i>
              </a>
              <ModalHeader
                className="p-3 "
                toggle={() => {
                  setOpenNcml(false);
                }}
              >
                {/* <div style={{ display: "flex", flexGrow: "1" }}>
            <div>2</div>
            <div>1</div>
          </div> */}
                {/* <p>Lead Details</p>
          <div>
            <i className=" ri-download-2-line"></i>
          </div> */}
              </ModalHeader>
            </div>
            <ModalBody style={{ overflowX: "scroll" }}>
              <Row>
                <Col
                  sm={12}
                  className="d-flex mb-5"
                  style={{ overflowX: "scroll", height: "60vh", width: "100%" }}
                >
                  <table style={{ width: "100%" }}>
                    <tr
                      className={classStyle.tr_leads}
                      key="111"
                      style={{ position: "sticky", top: "0px" }}
                    >
                      <td
                        className={classStyle.td_leads}
                        style={{ background: "#F2E7FF" }}
                      >
                        <p
                          style={{
                            margin: "0",
                            color: "#495057",
                          }}
                        >
                          Sl No
                        </p>
                      </td>
                      <td
                        className={classStyle.td_leads}
                        style={{ background: "#F2E7FF" }}
                      >
                        <p style={{ margin: "0", color: "#495057" }}>Title</p>
                      </td>
                      {/* <td
                        className={classStyle.td_leads}
                        style={{ background: '#F2E7FF' }}
                      >
                        <p style={{ margin: '0', color: '#495057' }}>Status</p>
                      </td> */}
                      <td
                        className={classStyle.td_leads}
                        style={{ background: "#F2E7FF" }}
                      >
                        <p style={{ margin: "0", color: "#495057" }}>Answer</p>
                      </td>
                      {/* <td
                        className={classStyle.td_leads}
                        style={{ background: '#F2E7FF' }}
                      >
                        <p style={{ margin: '0', color: '#495057' }}>Image</p>
                      </td> */}
                    </tr>
                    {leadData?.map((item, index) => {
                      return (
                        <>
                          <tr>
                            <td className={classStyle.td_leads}>
                              <p style={{ margin: "0", color: "#495057" }}>
                                {index + 1}
                              </p>
                            </td>
                            <td className={classStyle.td_leads}>
                              <p
                                style={{
                                  margin: "0",
                                  color: "#495057",
                                  height: "60px",
                                  alignItems: "center",
                                }}
                              >
                                {item.title}
                              </p>
                            </td>
                            {/* <td className={classStyle.td_leads}>
                              <p style={{ margin: '0', color: '#495057' }}>
                                {item.status ? item.status : '-'}
                              </p>
                            </td> */}
                            {item.type == "view" ? (
                              <td className={classStyle.td_leads}>
                                <p
                                  style={{
                                    margin: "0",
                                    color: "#495057",
                                  }}
                                >
                                  <img
                                    src={
                                      item.value
                                        ? item.value
                                        : require("../../../assets/images/no_image_available.png")
                                    }
                                    onError={({ currentTarget }) => {
                                      currentTarget.onerror = null; // prevents looping
                                      currentTarget.src = require("../../../assets/images/no_image_available.png");
                                    }}
                                    alt=""
                                    className="rounded avatar-md "
                                    style={{
                                      padding: "7px 7px 7px 0px",
                                      // marginRight: "4px",
                                    }}
                                    onClick={() => {
                                      setOpen2(true);
                                      setImageData(item.value);
                                    }}
                                  />
                                </p>
                              </td>
                            ) : item.type == "multiple_view" ? (
                              <td
                                className={classStyle.td_leads}
                                style={{ width: "400px" }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    width: "400px",
                                    overflowX: "scroll",
                                  }}
                                >
                                  {item.value.map((it) => {
                                    return (
                                      <>
                                        <div>
                                          <p
                                            style={{
                                              margin: "0",
                                              color: "#495057",
                                              // background: "green",
                                            }}
                                          >
                                            <img
                                              src={
                                                it
                                                  ? it
                                                  : require("../../../assets/images/no_image_available.png")
                                              }
                                              onError={({ currentTarget }) => {
                                                currentTarget.onerror = null; // prevents looping
                                                currentTarget.src = require("../../../assets/images/no_image_available.png");
                                              }}
                                              alt=""
                                              className="rounded avatar-md "
                                              style={{
                                                padding: "7px 7px 7px 0px",
                                              }}
                                              onClick={() => {
                                                setOpen2(true);
                                                setImageData(it);
                                              }}
                                            />
                                          </p>
                                        </div>
                                      </>
                                    );
                                  })}
                                </div>
                              </td>
                            ) : (
                              <td className={classStyle.td_leads}>
                                <p style={{ margin: "0", color: "#495057" }}>
                                  {item.value}
                                </p>
                              </td>
                            )}
                          </tr>
                        </>
                      );
                    })}
                  </table>
                </Col>
                <Col md={12}>
                  {/* <Card>
                    <CardHeader>
                      <h5 className="card-title mb-0">QC Recording</h5>
                    </CardHeader>
                    <CardBody style={{ minHeight: "150px" }}>
                      <h6>recording coming soon</h6>
                    </CardBody>
                  </Card> */}
                  {/* <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">
                    QC L1 -{" "}
                    <span>
                      {rowData?.internal_qc_status == "pending" ? (
                        <span
                          className="badge text-bg-warning"
                          style={{ width: "70px" }}
                        >
                          {rowData?.internal_qc_status}
                        </span>
                      ) : rowData?.internal_qc_status == "approved" ? (
                        <span
                          className="badge text-bg-success"
                          style={{ width: "70px" }}
                        >
                          {rowData?.internal_qc_status}
                        </span>
                      ) : rowData?.internal_qc_status == "rejected" ? (
                        <span
                          className="badge text-bg-danger"
                          style={{ width: "70px" }}
                        >
                          {rowData?.internal_qc_status}
                        </span>
                      ) : (
                        <></>
                      )}
                    </span>
                  </h5>
                </CardHeader>
                <CardBody style={{ minHeight: "70px" }}>
                  <h6 className="mute">
                    {rowData?.qc_remark?.length > 0
                      ? rowData?.qc_remark
                      : "No Remarks added"}
                  </h6>
                </CardBody>
              </Card>
              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">
                    QC L2 -{" "}
                    <span>
                      {rowData?.client_status == "pending" ? (
                        <span
                          className="badge text-bg-warning"
                          style={{ width: "70px" }}
                        >
                          {rowData?.client_status}
                        </span>
                      ) : rowData?.client_status == "approved" ? (
                        <span
                          className="badge text-bg-success"
                          style={{ width: "70px" }}
                        >
                          {rowData?.client_status}
                        </span>
                      ) : rowData?.client_status == "rejected" ? (
                        <span
                          className="badge text-bg-danger"
                          style={{ width: "70px" }}
                        >
                          {rowData?.client_status}
                        </span>
                      ) : (
                        <></>
                      )}
                    </span>
                  </h5>
                </CardHeader>
                <CardBody style={{ minHeight: "70px" }}>
                  <h6 className="mute">
                    {rowData?.client_status?.length > 0
                      ? rowData?.client_status
                      : "No Remarks added"}
                  </h6>
                </CardBody>
              </Card>
              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">
                    QC L3 -{" "}
                    <span>
                      {rowData?.final_qc == "pending" ? (
                        <span
                          className="badge text-bg-warning"
                          style={{ width: "70px" }}
                        >
                          {rowData?.final_qc}
                        </span>
                      ) : rowData?.final_qc == "approved" ? (
                        <span
                          className="badge text-bg-success"
                          style={{ width: "70px" }}
                        >
                          {rowData?.final_qc}
                        </span>
                      ) : rowData?.final_qc == "rejected" ? (
                        <span
                          className="badge text-bg-danger"
                          style={{ width: "70px" }}
                        >
                          {rowData?.final_qc}
                        </span>
                      ) : (
                        <></>
                      )}
                    </span>
                  </h5>
                </CardHeader>
                <CardBody style={{ minHeight: "70px" }}>
                  <h6 className="mute">
                    {rowData?.final_qc?.length > 0
                      ? rowData?.final_qc
                      : "No Remarks added"}
                  </h6>
                </CardBody>
              </Card> */}
                  <div
                    style={{
                      // a
                      bottom: "0px",
                      // top: "780px",
                      width: "100%",
                    }}
                  >
                    {user_type === "admin" && (
                      <div
                        // className="d-flex justify-content-evenly "
                        style={{
                          display: "flex",
                          justifyContent: "end",
                          gap: "10px",
                        }}
                      >
                        <button
                          type="button"
                          className="btn btn-success btn-label waves-effect waves-light"
                          onClick={() => setOpen3(true)}
                        >
                          <i className="ri-check-line label-icon align-middle fs-16 me-2"></i>{" "}
                          Approve
                        </button>
                        <button
                          type="button"
                          className="btn btn-danger btn-label waves-effect waves-light"
                          onClick={() => setOpen4(true)}
                        >
                          <i className=" ri-close-line label-icon align-middle fs-16 me-2"></i>
                          Reject
                        </button>
                      </div>
                    )}
                  </div>
                </Col>
              </Row>
            </ModalBody>
          </Modal>
        </>
      ) : (
        <>
          {open && (
            <Modal
              // className="modal-fullscreen"
              id="exampleModalFullscreen"
              tabIndex="-1"
              isOpen={open}
              toggle={() => {
                setOpen(false);
                setRowData({});
              }}
              centered={true}
              size={"xl"}
            >
              <div className="d-flex justify-content-end align-items-center">
                <a
                  href={`https://farming-backend.taskmo.in/common/downloadLead?lead_id=${rowData.main_lead}&token=${token}&project_id=${prjId}`}
                  target="_blank"
                  rel="noreferrer"
                  download
                  className="cursor-pointer "
                >
                  <i
                    className=" ri-download-2-line text-primary "
                    style={{ fontSize: "19px" }}
                  ></i>
                </a>
                <ModalHeader
                  className="p-3 "
                  toggle={() => {
                    setOpen(false);
                  }}
                >
                  {/* <div style={{ display: "flex", flexGrow: "1" }}>
            <div>2</div>
            <div>1</div>
          </div> */}
                  {/* <p>Lead Details</p>
          <div>
            <i className=" ri-download-2-line"></i>
          </div> */}
                </ModalHeader>
              </div>
              <ModalBody style={{ overflowX: "scroll" }}>
                <Row>
                  <Col
                    sm={12}
                    className="d-flex mb-5"
                    style={{
                      overflowX: "scroll",
                      height: "60vh",
                      width: "100%",
                    }}
                  >
                    <table style={{ width: "100%" }}>
                      <tr
                        className={classStyle.tr_leads}
                        key="111"
                        style={{ position: "sticky", top: "0px" }}
                      >
                        <td
                          className={classStyle.td_leads}
                          style={{ background: "#F2E7FF" }}
                        >
                          <p
                            style={{
                              margin: "0",
                              color: "#495057",
                            }}
                          >
                            Sl No
                          </p>
                        </td>
                        <td
                          className={classStyle.td_leads}
                          style={{ background: "#F2E7FF" }}
                        >
                          <p style={{ margin: "0", color: "#495057" }}>Title</p>
                        </td>
                        <td
                          className={classStyle.td_leads}
                          style={{ background: "#F2E7FF" }}
                        >
                          <p style={{ margin: "0", color: "#495057" }}>
                            Status
                          </p>
                        </td>
                        <td
                          className={classStyle.td_leads}
                          style={{ background: "#F2E7FF" }}
                        >
                          <p style={{ margin: "0", color: "#495057" }}>
                            Answer
                          </p>
                        </td>
                        <td
                          className={classStyle.td_leads}
                          style={{ background: "#F2E7FF" }}
                        >
                          <p style={{ margin: "0", color: "#495057" }}>Image</p>
                        </td>
                      </tr>
                      {/* <tr className={classStyle.tr_leads} key="121">
                  <td className={classStyle.td_leads}>
                    <p style={{ margin: "0", color: "#495057" }}>1</p>
                  </td>
                  <td className={classStyle.td_leads}>
                    <p style={{ margin: "0", color: "#495057" }}>
                      main lead id
                    </p>
                  </td>
                  <td className={classStyle.td_leads}>
                    <p style={{ margin: "0", color: "#495057" }}>
                      {dataArray.main_lead_id}
                    </p>
                  </td>
                </tr>
                <tr className={classStyle.tr_leads} key="131">
                  <td className={classStyle.td_leads}>
                    <p style={{ margin: "0", color: "#495057" }}>2</p>
                  </td>
                  <td className={classStyle.td_leads}>
                    <p style={{ margin: "0", color: "#495057" }}>
                      activity date
                    </p>
                  </td>
                  <td className={classStyle.td_leads}>
                    <p style={{ margin: "0", color: "#495057" }}>
                      {dataArray.activity_date}
                    </p>
                  </td>
                </tr>
                <tr className={classStyle.tr_leads} key="141">
                  <td className={classStyle.td_leads}>
                    <p style={{ margin: "0", color: "#495057" }}>3</p>
                  </td>
                  <td className={classStyle.td_leads}>
                    <p style={{ margin: "0", color: "#495057" }}>
                      customer name
                    </p>
                  </td>
                  <td className={classStyle.td_leads}>
                    <p style={{ margin: "0", color: "#495057" }}>
                      {dataArray.customer_name}
                    </p>
                  </td>
                </tr>
                <tr className={classStyle.tr_leads} key="151">
                  <td className={classStyle.td_leads}>
                    <p style={{ margin: "0", color: "#495057" }}>4</p>
                  </td>
                  <td className={classStyle.td_leads}>
                    <p style={{ margin: "0", color: "#495057" }}>
                      customer number
                    </p>
                  </td>
                  <td className={classStyle.td_leads}>
                    <p style={{ margin: "0", color: "#495057" }}>
                      {dataArray.customer_number}
                    </p>
                  </td>
                </tr>
                <tr className={classStyle.tr_leads} key="161">
                  <td className={classStyle.td_leads}>
                    <p style={{ margin: "0", color: "#495057" }}>5</p>
                  </td>
                  <td className={classStyle.td_leads}>
                    <p style={{ margin: "0", color: "#495057" }}>
                      final qc status
                    </p>
                  </td>
                  <td className={classStyle.td_leads}>
                    <p style={{ margin: "0", color: "#495057" }}>
                      {dataArray.final_qc_status}
                    </p>
                  </td>
                </tr>
                {colArray?.map((col, index) => {
                  return (
                    <>
                      <tr className={classStyle.tr_leads} key={index}>
                        <td className={classStyle.td_leads}>
                          <p style={{ margin: "0", color: "#495057" }}>
                            {index + 6}
                          </p>
                        </td>
                        <td className={classStyle.td_leads}>
                          <p style={{ margin: "0", color: "#495057" }}>
                            {col.split("_").join(" ")}
                          </p>
                        </td>
                        <td className={classStyle.td_leads}>
                          <p style={{ margin: "0", color: "#495057" }}>
                            {dataArray[`${col}`]}
                          </p>
                        </td>
                      </tr>
                    </>
                  );
                })}
                {viewArray?.map((col, index) => {
                  return (
                    <>
                      <tr className={classStyle.tr_leads} key={index}>
                        <td></td>
                        <td className={classStyle.td_leads}>
                          <p style={{ margin: "0", color: "#495057" }}>
                            {col.split("_").join(" ")}
                          </p>
                        </td>
                        <td className={classStyle.td_leads}>
                         

                          <img
                            
                            src={dataArray[`${col}`]}
                            alt=""
                            className="rounded avatar-md py-2"
                            onClick={() => {
                              setOpen2(true);
                              setImageData(dataArray[`${col}`]);
                            }}
                          />
                        </td>
                      </tr>
                    </>
                  );
                })} */}
                      {leadData?.map((item, index) => {
                        return (
                          <>
                            <tr>
                              <td className={classStyle.td_leads}>
                                <p style={{ margin: "0", color: "#495057" }}>
                                  {index + 1}
                                </p>
                              </td>
                              <td className={classStyle.td_leads}>
                                <p style={{ margin: "0", color: "#495057" }}>
                                  {item.title}
                                </p>
                              </td>
                              <td className={classStyle.td_leads}>
                                <p style={{ margin: "0", color: "#495057" }}>
                                  {item.status ? item.status : "-"}
                                </p>
                              </td>
                              <td className={classStyle.td_leads}>
                                <p style={{ margin: "0", color: "#495057" }}>
                                  {item.value}
                                </p>
                              </td>
                              <td className={classStyle.td_leads}>
                                <p style={{ margin: "0", color: "#495057" }}>
                                  <img
                                    src={
                                      item.image
                                        ? item.image
                                        : require("../../../assets/images/no_image_available.png")
                                    }
                                    onError={({ currentTarget }) => {
                                      currentTarget.onerror = null; // prevents looping
                                      currentTarget.src = require("../../../assets/images/no_image_available.png");
                                    }}
                                    alt=""
                                    className="rounded avatar-md "
                                    style={{ padding: "7px 7px 7px 0px" }}
                                    onClick={() => {
                                      setOpen2(true);
                                      setImageData(item.image);
                                    }}
                                  />
                                </p>
                              </td>
                            </tr>
                          </>
                        );
                      })}
                    </table>
                  </Col>
                  <Col md={12}>
                    {/* <Card>
                    <CardHeader>
                      <h5 className="card-title mb-0">QC Recording</h5>
                    </CardHeader>
                    <CardBody style={{ minHeight: "150px" }}>
                      <h6>recording coming soon</h6>
                    </CardBody>
                  </Card> */}
                    {/* <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">
                    QC L1 -{" "}
                    <span>
                      {rowData?.internal_qc_status == "pending" ? (
                        <span
                          className="badge text-bg-warning"
                          style={{ width: "70px" }}
                        >
                          {rowData?.internal_qc_status}
                        </span>
                      ) : rowData?.internal_qc_status == "approved" ? (
                        <span
                          className="badge text-bg-success"
                          style={{ width: "70px" }}
                        >
                          {rowData?.internal_qc_status}
                        </span>
                      ) : rowData?.internal_qc_status == "rejected" ? (
                        <span
                          className="badge text-bg-danger"
                          style={{ width: "70px" }}
                        >
                          {rowData?.internal_qc_status}
                        </span>
                      ) : (
                        <></>
                      )}
                    </span>
                  </h5>
                </CardHeader>
                <CardBody style={{ minHeight: "70px" }}>
                  <h6 className="mute">
                    {rowData?.qc_remark?.length > 0
                      ? rowData?.qc_remark
                      : "No Remarks added"}
                  </h6>
                </CardBody>
              </Card>
              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">
                    QC L2 -{" "}
                    <span>
                      {rowData?.client_status == "pending" ? (
                        <span
                          className="badge text-bg-warning"
                          style={{ width: "70px" }}
                        >
                          {rowData?.client_status}
                        </span>
                      ) : rowData?.client_status == "approved" ? (
                        <span
                          className="badge text-bg-success"
                          style={{ width: "70px" }}
                        >
                          {rowData?.client_status}
                        </span>
                      ) : rowData?.client_status == "rejected" ? (
                        <span
                          className="badge text-bg-danger"
                          style={{ width: "70px" }}
                        >
                          {rowData?.client_status}
                        </span>
                      ) : (
                        <></>
                      )}
                    </span>
                  </h5>
                </CardHeader>
                <CardBody style={{ minHeight: "70px" }}>
                  <h6 className="mute">
                    {rowData?.client_status?.length > 0
                      ? rowData?.client_status
                      : "No Remarks added"}
                  </h6>
                </CardBody>
              </Card>
              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">
                    QC L3 -{" "}
                    <span>
                      {rowData?.final_qc == "pending" ? (
                        <span
                          className="badge text-bg-warning"
                          style={{ width: "70px" }}
                        >
                          {rowData?.final_qc}
                        </span>
                      ) : rowData?.final_qc == "approved" ? (
                        <span
                          className="badge text-bg-success"
                          style={{ width: "70px" }}
                        >
                          {rowData?.final_qc}
                        </span>
                      ) : rowData?.final_qc == "rejected" ? (
                        <span
                          className="badge text-bg-danger"
                          style={{ width: "70px" }}
                        >
                          {rowData?.final_qc}
                        </span>
                      ) : (
                        <></>
                      )}
                    </span>
                  </h5>
                </CardHeader>
                <CardBody style={{ minHeight: "70px" }}>
                  <h6 className="mute">
                    {rowData?.final_qc?.length > 0
                      ? rowData?.final_qc
                      : "No Remarks added"}
                  </h6>
                </CardBody>
              </Card> */}
                    <div
                      style={{
                        // a
                        bottom: "0px",
                        // top: "780px",
                        width: "100%",
                      }}
                    >
                      {user_type === "admin" && (
                        <div
                          // className="d-flex justify-content-evenly "
                          style={{
                            display: "flex",
                            justifyContent: "end",
                            gap: "10px",
                          }}
                        >
                          <button
                            type="button"
                            className="btn btn-success btn-label waves-effect waves-light"
                            onClick={() => setOpen3(true)}
                          >
                            <i className="ri-check-line label-icon align-middle fs-16 me-2"></i>{" "}
                            Approve
                          </button>
                          <button
                            type="button"
                            className="btn btn-danger btn-label waves-effect waves-light"
                            onClick={() => setOpen4(true)}
                          >
                            <i className=" ri-close-line label-icon align-middle fs-16 me-2"></i>
                            Reject
                          </button>
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>
              </ModalBody>
            </Modal>
          )}
        </>
      )}

      <Modal
        isOpen={open2}
        toggle={() => {
          setOpen2(false);
        }}
        centered={true}
      >
        <img src={imageData} className="img-fluid" alt="image_pho" />
      </Modal>
      {/* <Modal
        // id="signupModals"
        size="xl"
        className="modal-fullscreen"
        id="exampleModalFullscreen"
        tabIndex="-1"
        isOpen={open}
        toggle={() => {
          setOpen(false);
        }}
        centered={true}
      >
        <ModalHeader
          className="p-3"
          toggle={() => {
            setOpen(false);
          }}
        ></ModalHeader>
        <ModalBody style={{ overflowX: "scroll" }}>
          <Row>
            <Col md={6}>
              <table style={{ width: "100%" }}>
                <tr className={classStyle.tr_leads} key="111">
                  <td className={classStyle.td_leads}>
                    <p style={{ margin: "0", color: "#495057" }}>Lead Name</p>
                  </td>
                  <td className={classStyle.td_leads}>
                    <p style={{ margin: "0", color: "#495057" }}>
                      Lead Details
                    </p>
                  </td>
                </tr>
                <tr className={classStyle.tr_leads} key="121">
                  <td className={classStyle.td_leads}>
                    <p style={{ margin: "0", color: "#495057" }}>
                      main_lead_id
                    </p>
                  </td>
                  <td className={classStyle.td_leads}>
                    <p style={{ margin: "0", color: "#495057" }}>
                      {dataArray.main_lead_id}
                    </p>
                  </td>
                </tr>
                <tr className={classStyle.tr_leads} key="131">
                  <td className={classStyle.td_leads}>
                    <p style={{ margin: "0", color: "#495057" }}>
                      activity_date
                    </p>
                  </td>
                  <td className={classStyle.td_leads}>
                    <p style={{ margin: "0", color: "#495057" }}>
                      {dataArray.activity_date}
                    </p>
                  </td>
                </tr>
                <tr className={classStyle.tr_leads} key="141">
                  <td className={classStyle.td_leads}>
                    <p style={{ margin: "0", color: "#495057" }}>
                      customer_name
                    </p>
                  </td>
                  <td className={classStyle.td_leads}>
                    <p style={{ margin: "0", color: "#495057" }}>
                      {dataArray.customer_name}
                    </p>
                  </td>
                </tr>
                <tr className={classStyle.tr_leads} key="151">
                  <td className={classStyle.td_leads}>
                    <p style={{ margin: "0", color: "#495057" }}>
                      customer_number
                    </p>
                  </td>
                  <td className={classStyle.td_leads}>
                    <p style={{ margin: "0", color: "#495057" }}>
                      {dataArray.customer_number}
                    </p>
                  </td>
                </tr>
                <tr className={classStyle.tr_leads} key="161">
                  <td className={classStyle.td_leads}>
                    <p style={{ margin: "0", color: "#495057" }}>
                      final_qc_status
                    </p>
                  </td>
                  <td className={classStyle.td_leads}>
                    <p style={{ margin: "0", color: "#495057" }}>
                      {dataArray.final_qc_status}
                    </p>
                  </td>
                </tr>
                {colArray.map((col, index) => {
                  return (
                    <>
                      <tr className={classStyle.tr_leads} key={index}>
                        <td className={classStyle.td_leads}>
                          <p style={{ margin: "0", color: "#495057" }}>{col}</p>
                        </td>
                        <td className={classStyle.td_leads}>
                          <p style={{ margin: "0", color: "#495057" }}>
                            {dataArray[`${col}`]}
                          </p>
                        </td>
                      </tr>
                    </>
                  );
                })}
                {viewArray.map((col, index) => {
                  return (
                    <>
                      <tr className={classStyle.tr_leads} key={index}>
                        <td className={classStyle.td_leads}>
                          <p style={{ margin: "0", color: "#495057" }}>{col}</p>
                        </td>
                        <td className={classStyle.td_leads}>
                          

                          <img
                            // style={{display:'inline-block',padding:'8px 0 8px 0'}}
                            src={dataArray[`${col}`]}
                            alt=""
                            className="rounded avatar-md py-2"
                            onClick={() => {
                              setOpen2(true);
                              setImageData(dataArray[`${col}`]);
                            }}
                          />
                        </td>
                      </tr>
                    </>
                  );
                })}
              </table>
            </Col>
            <Col md={6}>
              
              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">
                    QC L1 -{" "}
                    <span>
                      {rowData?.internal_qc_status == "pending" ? (
                        <span
                          className="badge text-bg-warning"
                          style={{ width: "70px" }}
                        >
                          {rowData?.internal_qc_status}
                        </span>
                      ) : rowData?.internal_qc_status == "approved" ? (
                        <span
                          className="badge text-bg-success"
                          style={{ width: "70px" }}
                        >
                          {rowData?.internal_qc_status}
                        </span>
                      ) : rowData?.internal_qc_status == "rejected" ? (
                        <span
                          className="badge text-bg-danger"
                          style={{ width: "70px" }}
                        >
                          {rowData?.internal_qc_status}
                        </span>
                      ) : (
                        <></>
                      )}
                    </span>
                  </h5>
                </CardHeader>
                <CardBody style={{ minHeight: "70px" }}>
                  <h6 className="mute">
                    {rowData?.qc_remark?.length > 0
                      ? rowData?.qc_remark
                      : "No Remarks added"}
                  </h6>
                </CardBody>
              </Card>
              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">
                    QC L2 -{" "}
                    <span>
                      {rowData?.client_status == "pending" ? (
                        <span
                          className="badge text-bg-warning"
                          style={{ width: "70px" }}
                        >
                          {rowData?.client_status}
                        </span>
                      ) : rowData?.client_status == "approved" ? (
                        <span
                          className="badge text-bg-success"
                          style={{ width: "70px" }}
                        >
                          {rowData?.client_status}
                        </span>
                      ) : rowData?.client_status == "rejected" ? (
                        <span
                          className="badge text-bg-danger"
                          style={{ width: "70px" }}
                        >
                          {rowData?.client_status}
                        </span>
                      ) : (
                        <></>
                      )}
                    </span>
                  </h5>
                </CardHeader>
                <CardBody style={{ minHeight: "70px" }}>
                  <h6 className="mute">
                    {rowData?.client_status?.length > 0
                      ? rowData?.client_status
                      : "No Remarks added"}
                  </h6>
                </CardBody>
              </Card>
              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">
                    QC L3 -{" "}
                    <span>
                      {rowData?.final_qc == "pending" ? (
                        <span
                          className="badge text-bg-warning"
                          style={{ width: "70px" }}
                        >
                          {rowData?.final_qc}
                        </span>
                      ) : rowData?.final_qc == "approved" ? (
                        <span
                          className="badge text-bg-success"
                          style={{ width: "70px" }}
                        >
                          {rowData?.final_qc}
                        </span>
                      ) : rowData?.final_qc == "rejected" ? (
                        <span
                          className="badge text-bg-danger"
                          style={{ width: "70px" }}
                        >
                          {rowData?.final_qc}
                        </span>
                      ) : (
                        <></>
                      )}
                    </span>
                  </h5>
                </CardHeader>
                <CardBody style={{ minHeight: "70px" }}>
                  <h6 className="mute">
                    {rowData?.final_qc?.length > 0
                      ? rowData?.final_qc
                      : "No Remarks added"}
                  </h6>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
      <Modal
        isOpen={open2}
        toggle={() => {
          setOpen2(false);
        }}
        centered={true}
      >
        <img src={imageData} className="img-fluid" alt="image_pho" />
      </Modal> */}
      <Modal
        isOpen={open3}
        toggle={() => {
          setOpen3(false);
        }}
        centered={true}
      >
        <ModalHeader>Remarks</ModalHeader>
        <ModalBody>
          <div>
            {/* <label htmlFor="exampleFormControlTextarea5" className="form-label">
              Remarks
            </label> */}
            <textarea
              className="form-control"
              id="exampleFormControlTextarea5"
              rows="3"
              onChange={(e) => setAppRemark(e.target.value)}
            ></textarea>
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            className="btn btn-primary waves-effect waves-light"
            onClick={() => handleApprove()}
          >
            Submit
          </button>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={open4}
        toggle={() => {
          setOpen4(false);
        }}
        centered={true}
      >
        <ModalHeader>Remarks</ModalHeader>
        <ModalBody>
          <div>
            {/* <label htmlFor="exampleFormControlTextarea5" className="form-label">
              Remarks
            </label> */}
            <textarea
              className="form-control"
              id="exampleFormControlTextarea5"
              rows="3"
              onChange={(e) => setRejRemark(e.target.value)}
            ></textarea>
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            className="btn btn-primary waves-effect waves-light"
            onClick={() => handleReject()}
          >
            Submit
          </button>
        </ModalFooter>
      </Modal>
    </>
  );
};
