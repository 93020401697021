import axios from "axios";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { APIClient } from "../assets/config/sessionToken";
import { project_lists_client } from "../assets/utils/dashboard";
import { oa_sow_list } from "../assets/utils/sow";
import { api } from "../globalConfig";
import base64 from "base-64";

const Navdata = () => {
  const history = useHistory();
  //state data
  const [isDashboard, setIsDashboard] = useState(true);
  const [isApps, setIsApps] = useState(false);
  const [isAuth, setIsAuth] = useState(false);
  const [isPages, setIsPages] = useState(false);
  const [isSow, setIsSow] = useState(false);
  const [isAdvanceUi, setIsAdvanceUi] = useState(false);
  const [isForms, setIsForms] = useState(false);
  const [isTables, setIsTables] = useState(false);
  const [isCharts, setIsCharts] = useState(false);
  const [isIcons, setIsIcons] = useState(false);
  const [isMaps, setIsMaps] = useState(false);
  const [isMultiLevel, setIsMultiLevel] = useState(false);

  // Apps
  const [isEmail, setEmail] = useState(false);
  const [isSubEmail, setSubEmail] = useState(false);
  const [isEcommerce, setIsEcommerce] = useState(false);
  const [isProjects, setIsProjects] = useState(false);
  const [isTasks, setIsTasks] = useState(false);
  const [isCRM, setIsCRM] = useState(false);
  const [isCrypto, setIsCrypto] = useState(false);
  const [isInvoices, setIsInvoices] = useState(false);
  const [isSupportTickets, setIsSupportTickets] = useState(false);
  const [isNFTMarketplace, setIsNFTMarketplace] = useState(false);

  // Authentication
  const [isSignIn, setIsSignIn] = useState(false);
  const [isSignUp, setIsSignUp] = useState(false);
  const [isPasswordReset, setIsPasswordReset] = useState(false);
  const [isPasswordCreate, setIsPasswordCreate] = useState(false);
  const [isLockScreen, setIsLockScreen] = useState(false);
  const [isLogout, setIsLogout] = useState(false);
  const [isSuccessMessage, setIsSuccessMessage] = useState(false);
  const [isVerification, setIsVerification] = useState(false);
  const [isError, setIsError] = useState(false);

  // Pages
  const [isProfile, setIsProfile] = useState(false);
  const [isLanding, setIsLanding] = useState(false);

  // Charts
  const [isApex, setIsApex] = useState(false);

  // Multi Level
  const [isLevel1, setIsLevel1] = useState(false);
  const [isLevel2, setIsLevel2] = useState(false);

  const [iscurrentState, setIscurrentState] = useState("Dashboard");
  const [sub_items, set_sub_items] = useState([]);

  function updateIconSidebar(e) {
    if (e && e.target && e.target.getAttribute("subitems")) {
      const ul = document.getElementById("two-column-menu");
      const iconItems = ul.querySelectorAll(".nav-icon.active");
      let activeIconItems = [...iconItems];
      activeIconItems.forEach((item) => {
        item.classList.remove("active");
        var id = item.getAttribute("subitems");
        if (document.getElementById(id))
          document.getElementById(id).classList.remove("show");
      });
    }
  }

  useEffect(() => {
    document.body.classList.remove("twocolumn-panel");
    if (iscurrentState !== "Dashboard") {
      setIsDashboard(false);
    }
    if (iscurrentState !== "Apps") {
      setIsApps(false);
    }
    if (iscurrentState !== "Auth") {
      setIsAuth(false);
    }
    if (iscurrentState !== "Pages") {
      setIsPages(false);
    }
    if (iscurrentState !== "Sow") {
      setIsSow(false);
    }
    if (iscurrentState !== "AdvanceUi") {
      setIsAdvanceUi(false);
    }
    if (iscurrentState !== "Forms") {
      setIsForms(false);
    }
    if (iscurrentState !== "Tables") {
      setIsTables(false);
    }
    if (iscurrentState !== "Charts") {
      setIsCharts(false);
    }
    if (iscurrentState !== "Icons") {
      setIsIcons(false);
    }
    if (iscurrentState !== "Maps") {
      setIsMaps(false);
    }
    if (iscurrentState !== "MuliLevel") {
      setIsMultiLevel(false);
    }
    if (iscurrentState === "Widgets") {
      history.push("/widgets");
      document.body.classList.add("twocolumn-panel");
    }
    if (iscurrentState !== "Landing") {
      setIsLanding(false);
    }
  }, [
    history,
    iscurrentState,
    isDashboard,
    isApps,
    isAuth,
    isPages,
    isSow,
    isAdvanceUi,
    isForms,
    isTables,
    isCharts,
    isIcons,
    isMaps,
    isMultiLevel,
  ]);

  // {
  //   id: "id",
  //   label: "sow id",
  //   link: "/sow-id",
  //   parentId: "Sow",
  // },
  const token = sessionStorage.getItem("token");

  useEffect(() => {
    const pathName = api.COMMON_URL + project_lists_client;

    const apiCall = new APIClient();

    token &&
      apiCall
        .get(pathName)
        .then((res) => {
          const arrayData = res.data?.data?.length > 0 && res.data.data;
          const projectId = res.data.data?.map((item) => item.project_id);

          arrayData.map((arr) => {
            const encode = base64.encode(arr.ref_table_name);

            arr["id"] = arr.ref_table_name;
            arr.label = `${arr.project_title}`;
            arr.link = `/dashboard/${arr.project_id}`;
            arr.parentId = "Dashboard";
            arr.tableName = `${encode}`;
            arr.colorCode = `${arr.color_code}`;
            // arr.tableApi = `${encodeApi}`;
            arr.brandLogo = `${arr.brand_logo}`;
          });
          set_sub_items(arrayData);
          history.push(
            `/dashboard/${arrayData[0]?.project_id}?name:${arrayData[0]?.tableName}`
          );
          (function () {
            sessionStorage.setItem(
              "project",
              JSON.stringify({
                project_name: arrayData[0]?.label,
                logo: arrayData[0]?.brand_logo,
                color_code: arrayData[0]?.colorCode,
              })
            );
            sessionStorage.setItem("projectID", JSON.stringify(projectId));
          })();
        })
        .catch((err) => {
          console.log(err);
        });
  }, [token]);

  const menuItems = [
    {
      label: "Menu",
      isHeader: true,
    },
    {
      id: "dashboard",
      label: "Dashboards",
      icon: "ri-dashboard-2-line",
      link: "/#",
      stateVariables: isDashboard,
      click: function (e) {
        e.preventDefault();
        setIsDashboard(!isDashboard);
        setIscurrentState("Dashboard");
        updateIconSidebar(e);

        // sessionStorage.setItem({})
      },
      stateVariables: isDashboard,
      subItems: sub_items,
      //test
    },

    // {
    //   id: "Sow",
    //   label: "Sow",
    //   icon: "ri-pencil-ruler-2-line",
    //   link: "/#",
    //   click: function (e) {
    //     e.preventDefault();
    //     setIsSow(!isSow);
    //     setIscurrentState("Sow");
    //     updateIconSidebar(e);
    //   },
    //   stateVariables: isSow,
    //   subItems: sub_items,
    // },
  ];
  return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;
